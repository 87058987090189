import { ApiBase } from '@/api/apis/ApiBase';
import { HttpVerb } from '@/api/types/enum/Http';
import { AcceptedRegulation, Company, CompanyDetail, CompanyTree, CreateCompany, IContractor, BarebonesCompanyInfo } from '@/api/types/model/Company';
import { ProjectLaw } from '@/api/types/model/Project';
import { ComplianceValidation } from '@/api/types/model/ComplianceValidation';
import { BasicEmployee, Employee } from '@/api/types/model/Employee';
import { PaginationReq } from '@/api/types/model/PaginationReq';
import { RecordValue } from '@/api/types/model/Record';

export class CompanyApi extends ApiBase {
  setRouteGroup (): string {
    return 'Companies';
  }

  public Paginate (paginate: PaginationReq) {
    return this.doPaginate<Company>({
      path: '',
      method: HttpVerb.GET,
      query: { ...paginate },
    });
  }

  public Get (companyId: number) {
    return this.doReq<CompanyDetail>({
      path: `${companyId}`,
      method: HttpVerb.GET,
    });
  }

  public Create (company: CreateCompany, adminEmail: string) {
    return this.doReq<Company>({
      path: 'create-company',
      method: HttpVerb.POST,
      data: { ...company },
      query: { adminEmail },
    });
  }

  public Update (companyId: number, company: Company) {
    return this.doReq<Company>({
      path: `${companyId}`,
      method: HttpVerb.PUT,
      data: { ...company },
    });
  }

  public GetDocuments (companyId: number) {
    return this.doReq<RecordValue[]>({
      path: `${companyId}/documents`,
      method: HttpVerb.GET,
    });
  }

  public GetEmployees (companyId: number, paginate: PaginationReq) {
    return this.doPaginate<Employee>({
      path: `${companyId}/employees`,
      method: HttpVerb.GET,
      query: { ...paginate },
    });
  }

  public UpdateAdmins (companyId: number, companyAdmins: BasicEmployee[]) {
    return this.doReq<Employee[]>({
      path: `${companyId}/admins`,
      method: HttpVerb.PUT,
      data: companyAdmins,
    });
  }

  public UpdateRegulations (companyId: number) {
    return this.doReq<AcceptedRegulation>({
      path: `${companyId}/regulations`,
      method: HttpVerb.POST,
    });
  }

  public GetComplianceValidations (companyId: number, paginate: PaginationReq) {
    return this.doPaginate<ComplianceValidation>({
      path: `${companyId}/compliance-validations`,
      method: HttpVerb.GET,
      query: { ...paginate },
    });
  }

  public CheckIfCompanyExist (registrationNumber: string) {
    return this.doRawReq<boolean>({
      path: 'check-company-exist',
      method: HttpVerb.GET,
      query: { registrationNumber },
    });
  }

  public CheckIfCompanyExistByVat (vatNumber: string) {
    return this.doRawReq<boolean>({
      path: 'check-company-exist-by-vat',
      method: HttpVerb.GET,
      query: { vatNumber },
    });
  }

  public CheckIfCompanyExistOnProject (registrationNumber: string, projectId: number) {
    return this.doRawReq<Company>({
      path: 'company-exist-project',
      method: HttpVerb.GET,
      query: { registrationNumber, projectId },
    });
  }

  public CheckIfCompanyExistOnProjectByVat (vatNumber: string, projectId: number) {
    return this.doRawReq<Company>({
      path: 'company-exist-project-by-vat',
      method: HttpVerb.GET,
      query: { vatNumber, projectId },
    });
  }

  public GetCompanyChain (companyId: number) {
    return this.doReq<CompanyTree>({
      path: 'company-chain',
      method: HttpVerb.GET,
      query: { companyId },
    });
  }

  public GetActiveProjects (companyId: number) {
    return this.doReq<ProjectLaw[]>({
      path: `${companyId}/active-projects`,
      method: HttpVerb.GET,
    });
  }

  public GetContractors (companyId: number) {
    return this.doRawReq<IContractor[]>({
      path: `${companyId}/contractors`,
      method: HttpVerb.GET,
    });
  }

  public SearchContractorsByName (contractorName: string) {
    return this.doRawReq<BarebonesCompanyInfo[]>({
      path: 'search-contractors-by-name',
      method: HttpVerb.GET,
      query: { contractorName },
    });
  }
}
