import { ApiBase } from '@/api/apis/ApiBase';
import { ContentType, Header, HttpVerb } from '@/api/types/enum/Http';
import { RegulationDocumentType } from '@/api/types/enum/RegulationDocumentType';
import { RegulationDocumentReq } from '@/api/types/model/FileReq';

export class RegulationDocumentApi extends ApiBase {
  setRouteGroup (): string {
    return 'RegulationDocuments';
  }

  public GetLatestDocument (documentType: RegulationDocumentType) {
    return this.doReq<string>({
      path: `${documentType}`,
      method: HttpVerb.GET,
    });
  }

  public Create (file: RegulationDocumentReq) {
    const formData = new FormData();

    formData.append('File', file.data as Blob, file.fileName || 'File');

    formData.append('type', file.type.toString());

    return this.doReq<boolean>({
      path: '',
      method: HttpVerb.POST,
      data: formData,
      headers: {
        [Header.contentType]: ContentType.FORMDATA,
      },
    });
  }
}
